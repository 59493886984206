export const CheckClientId = async (clientId: string): Promise<boolean> => {
  try {
    const url = `https://backend.mydogood.com/api/saleforce/clientId/${clientId}`;

    // Perform the fetch request
    const response = await fetch(url);

    // Check if the response is OK (status code 200-299)
    if (response.ok) {
      return true; // Success case
    } else {
      console.log("Fetch failed with status:", response.status);
      return false; // Fetch failed
    }
  } catch (error) {
    console.error("Error:", error);
    return false; // Error case
  }
};
