// src/components/layout/ContextProvidersWrapper.tsx
import { FC, ReactNode } from "react";
import {
  GetPartnersAndIncentivesContextProvider,
  IncentivesProvider,
  PartnerContextProvider,
} from "../components/sections/homeSection/contexts/IncentivesContext";

type Props = {
  children: ReactNode;
};

const ContextProvidersWrapper: FC<Props> = ({ children }) => {
  return (
    <GetPartnersAndIncentivesContextProvider>
      <PartnerContextProvider>
        <IncentivesProvider>{children}</IncentivesProvider>
      </PartnerContextProvider>
    </GetPartnersAndIncentivesContextProvider>
  );
};

export default ContextProvidersWrapper;
