import { FC, lazy } from "react";
import { Outlet, RouteObject } from "react-router-dom";

import Loader from "../components/layout/Loader";
import DefaultLayout from "../components/layout/Layout";
import ContextProvidersWrapper from "../layout/ContextProvidersWrapper";

const routes: RouteObject[] = [
  {
    Component: DefaultLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: lazy<FC>(() => import("./Login")),
        path: "/",
        index: true,
      },
      {
        path: "/:clientId", // Dynamic paths
        element: (
          <ContextProvidersWrapper>
            <Outlet /> {/* Allows children routes to render */}
          </ContextProvidersWrapper>
        ),
        children: [
          {
            Component: lazy<FC>(() => import("./Home")),
            path: "home",
            index: true,
          },
          {
            Component: lazy<FC>(() => import("./Home")),
            path: "",
            index: true,
          },
          {
            Component: lazy<FC>(() => import("./Meetings")),
            path: "meetings",
            index: true,
          },
          {
            Component: lazy<FC>(() => import("./Incentives")),
            path: "incentives",
            index: true,
          },
          {
            Component: lazy<FC>(() => import("./Profile")),
            path: "profile",
            index: true,
          },
          {
            Component: lazy<FC>(() => import("./Bonus")),
            path: "bonus",
            index: true,
          },
        ],
      },
    ],
  },
];

export default routes;
